import React, { useState } from 'react';
import { closeModal, openModal } from '@redq/reuse-modal';
import '@redq/reuse-modal/lib/index.css';
import { ChipStyle } from './LeadGrid';
import technologies from '../../../utils/technologies.js';
import Button from 'common/src/components/Button';
import Icon from 'react-icons-kit';
import { eye } from 'react-icons-kit/fa/eye';
import { eyeSlash } from 'react-icons-kit/fa/eyeSlash';
import Text from 'common/src/components/Text';
import SearchPanel from '../../App/SearchPanel';
import * as Utils from '../../../utils/Utils';
import { allTechKeys, buttonize, nFormatter, processedTechs } from '../../../utils/Utils';
import { LeadSectionProps } from './index';
import Card from 'common/src/components/Card';
import Navbar from '../../SaasModern/Navbar';
import { rocket as flash } from 'react-icons-kit/entypo/rocket';
import techPrices from '../../../utils/prices.json';
import Link from 'common/src/components/Link';

const BigModalComponent = ({ updateSearchAndClose }) => {
  const [mode, setMode] = useState(true);
  const availableCategoryKeys = [...new Set(allTechKeys.map(techKey => {
    return technologies.apps[techKey].cats;
  }).flat())].sort((a, b) =>
    technologies.categories[a].name.localeCompare(technologies.categories[b].name));

  const catTechnologies = {};
  availableCategoryKeys.forEach(catKey => {
    catTechnologies[catKey] = [];
  });
  for (let techKey of allTechKeys) {
    for (const techCatKey of technologies.apps[techKey].cats)
      catTechnologies[techCatKey.toString()].push(techKey);
  }
  availableCategoryKeys.forEach(catKey => {
    catTechnologies[catKey].sort();
  });

  // console.log('grrr cat', catTechnologies);
  const modeText = mode ? 'Show categories and technologies' : 'Show only categories';
  const modeIcon = mode ? eye : eyeSlash;


  return (
    <div id="modalContainer"
         style={{ overflowY: 'scroll', height: '100%', backgroundColor: 'white', padding: '15px' }}>
      <Button
        variant="textButton"
        onClick={() => setMode(!mode)}
        className="mx-auto"
        iconPosition="left"
        icon={<Icon icon={modeIcon} size={14}/>}
        title={modeText}
        style={{
          fontSize: '14px',
          fontWeight: 400
        }}/>
      {updateSearchAndClose && <p style={{ marginTop: 0, fontSize: '13px' }}>
        Click on a category or technology to find leads
      </p>}
      <div>
        {
          availableCategoryKeys.map(it =>
            <>
              <div style={{ display: 'flex' }}>
                <h4 style={{ marginBottom: '12px', marginTop: '12px', cursor: 'pointer' }}
                    {...buttonize(() => updateSearchAndClose && updateSearchAndClose(technologies.categories[it].name))}>
                  {technologies.categories[it].name} ({catTechnologies[it].length})
                </h4>
              </div>
              {mode && <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {catTechnologies[it].map(techKey =>
                  <span
                    className="mx-auto"
                    {...buttonize(() => updateSearchAndClose && updateSearchAndClose(techKey))}
                    style={{
                      ...ChipStyle,
                      display: 'flex',
                      cursor: 'pointer'
                    }}>
                    {/*<span style={{
                      height: '14px',
                      width: '14px',
                      marginRight: '6px'
                    }}>
                      <Image
                        alt={techKey}
                        src={`https://static.coscout.com/images/tech/${technologies.apps[techKey].icon}`}
                        style={{
                          height: '14px',
                          width: '14px'
                        }}
                      />
                    </span>*/}
                    {techKey}
                  </span>)}
              </div>}
            </>)
        }
      </div>
      <div style={{ height: '40px' }}/>
    </div>
  );
};

const LeadModal = ({ updateSearch }) => {
  const updateSearchAndClose = s => {
    updateSearch(s);
    closeModal();
  };
  return (
    <>
      <Text content="View all categories" {...SearchPanel.defaultProps.hintStyle}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => openTechnologiesModal(updateSearchAndClose)}/>
    </>
  );
};

export const openTechnologiesModal = (updateSearchAndClose) =>
  openModal({
    ...MODAL_CONFIG,
    component: BigModalComponent,
    componentProps: { updateSearchAndClose }
  });


export const openParametersModal = () =>
  openModal({
    ...MODAL_CONFIG,
    closeOnClickOutside: true,
    component: ParametersModal
  });

export const AddToCartModal = ({ techKey }) => {
  const [subButtonDisabled, setSubButtonDisabled] = useState(false);
  const tech = processedTechs.apps[techKey];
  const prices = [10, 50, 100];
  const purchaseOptions = [];
  if (tech.count < 500) {
    purchaseOptions.push(tech.count);
  } else if (tech.count < 5000) {
    purchaseOptions.push(100);
    purchaseOptions.push(tech.count);
  } else {
    purchaseOptions.push(100);
    purchaseOptions.push(5000);
    if (tech.count < 20000)
      purchaseOptions.push(tech.count);
    else
      purchaseOptions.push(20000);
  }

  const buySubscription = async () => {
    setSubButtonDisabled(true);
    await Utils.openSubscriptionCheckout();
    setTimeout(() => setSubButtonDisabled(false), 5000);
  };

  const priceDescriptions = ['small', 'medium', 'large'];

  const buyList = async (techKey, i) => {
    const techPrice = techPrices.find(it => it['Product Name'] === techKey &&
      priceDescriptions[i] === it['Description']);
    await Utils.openOneTimeCheckout('price_' + techPrice['Price ID']);
  };

  return (
    <div id="modalContainer3"
         style={{ overflowY: 'auto', height: '100%', backgroundColor: 'white', padding: '15px' }}>
      <p style={{ marginBottom: '4px', marginLeft: '15px' }}>
        How many leads would you like to purchase?
      </p>
      <Text {...LeadSectionProps.cardText}
            style={{ marginLeft: '15px' }}
            content={`You will get an email with a detailed CSV of companies that use ${techKey}.`}/>
      <div>
        {purchaseOptions.map((it, i) =>
          <Card {...LeadSectionProps.cardStyle} minHeight="10%"
                m="8px">
            <div style={{
              display: 'flex',
              width: '100%'
            }}>
              <div>
                <Text
                  content={`${nFormatter(it)} leads`}
                  style={{
                    color: 'black',
                    fontWeight: 500,
                    fontSize: '19px',
                    marginBottom: '0'
                  }}
                />
                {/* customers of ${techKey} for */}
                <Text
                  content={`$${prices[i]}`}
                  {...LeadSectionProps.cardText}
                  style={{ marginBottom: '0px', marginRight: '12px' }}
                />
              </div>
              <Button
                colors="primaryWithBg"
                icon={<Icon icon={flash} size={16} style={{ marginBottom: '4px' }}/>}
                title="BUY NOW"
                onClick={() => buyList(techKey, i)}
                disabled={subButtonDisabled}
                style={{
                  marginLeft: 'auto',
                  fontSize: '16px',
                  fontWeight: 400,
                  padding: '0 8px',
                  height: '24px',
                  minWidth: '110px'
                }}/>
            </div>
          </Card>)}
      </div>
      {/*<div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        {purchaseOptions.map((it, i) =>
          <Card {...LeadSectionProps.cardStyle} minHeight="10%" m="8px">
            <Text {...{
              as: 'span',
              display: 'block',
              textAlign: 'center',
              fontSize: '14px',
              letterSpacing: '0.15em',
              fontWeight: '700',
              color: '#2aa275',
              mb: '5px'
            }}
                  content={"$50"}/>
            <div style={{
              display: 'flex',
              width: '100%'
            }}>
              <Text
                content={`${nFormatter(it)} leads`}
                style={{
                  color: 'black',
                  fontWeight: 500,
                  fontSize: '16px',
                  marginBottom: '8px',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
            </div>
            <Button
              // variant="outlined"
              iconPosition="left"
              colors="primaryWithBg"
              icon={<Icon icon={cart} size={12}/>}
              title={`Add to cart $${prices[i]}`}
              style={{
                marginLeft: 'auto',
                fontSize: '12px',
                fontWeight: 400,
                padding: '0 8px',
                height: '24px'
              }}/>
          </Card>)}
      </div>*/}
      <Card {...LeadSectionProps.cardStyle} minHeight="10%"
            m="8px">
        <div style={{ display: 'flex' }}>
          <div style={{
            width: '100%'
          }}>
            <div style={{ display: 'flex' }}>
              <div style={ChipStyle}>
                Most popular
              </div>
              <div style={ChipStyle}>
                50% off
              </div>
            </div>
            <Text
              content="Subscribe and save!"
              style={{
                marginRight: 'auto',
                color: 'black',
                fontWeight: 500,
                fontSize: '19px',
                marginBottom: '0'
              }}
            />
            <Text
              content={<>
                Get unlimited access to ALL leads for only $199/year
                <br/>(<Link href="/">all categories</Link>!)
              </>}
              {...LeadSectionProps.cardText}
              style={{ marginBottom: '0', marginRight: '12px' }}
            />
          </div>
          <Button
            title="SUBSCRIBE"
            disabled={subButtonDisabled}
            onClick={buySubscription}
            // icon={<Icon icon={angleRight} size={14}/>}
            {...Navbar.defaultProps.button}
            style={{
              marginLeft: 'auto',
              fontSize: '16px',
              padding: '2px 6px',
              marginTop: '20px',
              minWidth: '110px'
            }}/>
        </div>
      </Card>
      <div style={{ height: '40px' }}/>
    </div>
  );
};

export const openAddToCartModal = (techKey) =>
  openModal({
    ...MODAL_CONFIG,
    // config: {
    //   height: 400
    // },
    component: AddToCartModal,
    componentProps: { techKey }
  });

const ParametersModal = () => {
  return (
    <div id="modalContainer2"
         style={{ overflowY: 'scroll', height: '100%', backgroundColor: 'white', padding: '15px' }}>
      <p>
        You get an email with a CSV of companies with the following 20 parameters:
      </p>
      <ul>
        <li>• Company name</li>
        <li>• Website</li>
        <li>• Description</li>
        <li>• City</li>
        <li>• State</li>
        <li>• Country</li>
        <li>• Continent</li>
        <li>• Email</li>
        <li>• Industry</li>
        <li>• Number of employees</li>
        <li>• Revenue</li>
        <li>• Total funding</li>
        <li>• Latest funding round date</li>
        <li>• Phone</li>
        <li>• Twitter</li>
        <li>• Facebook</li>
        <li>• LinkedIn</li>
        <li>• CEO name</li>
        <li>• CEO LinkedIn</li>
        <li>• CEO Twitter</li>
      </ul>

      <div style={{ height: '20px' }}/>
    </div>
  );
};

const MODAL_CONFIG = {
  config: {
    width: 580,
    height: 500,
    animationFrom: { transform: 'scale(1)' },
    animationTo: { transform: 'scale(1)' }
  },
  withRnd: false,
  closeOnClickOutside: true
};

export default LeadModal;
