import React, { useState } from 'react';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import SearchPanel from '../../App/SearchPanel';
import LeadGrid from './LeadGrid';

const LeadSection = () => {
  const [search, setSearch] = useState('');
  return (
    <Box {...LeadSectionProps.sectionWrapper} id="leads" as="section">
      <Container>
        <Box
          {...LeadSectionProps.secTitleWrapper}
          style={{ marginBottom: '30px' }}
        >
          <div style={{ height: '5rem' }}/>
          <Text
            {...LeadSectionProps.secText}
            content="FIND LEADS BY TECHNOLOGY"
          />
          <Heading
            {...LeadSectionProps.secHeading}
            content="Add to cart, checkout and get a CSV in your email ⚡"
          />
        </Box>
      </Container>
      <Container>
        <Box style={{ marginBottom: '30px' }}>
          <SearchPanel search={search} setSearch={setSearch}/>
        </Box>
      </Container>
      <Container>
        <LeadGrid search={search}/>
      </Container>
    </Box>
  );
};

export const LeadSectionProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  colOne: {
    width: ['100%'],
    flexBox: true,
    flexWrap: 'wrap'
  },
  col: {
    width: ['100%', '50%', '50%', '33%', '33%', '33%'],
    pl: ['15px', '0px'],
    pr: ['15px', '0px'],
    mb: '0px'
  },
  sectionWrapper: {
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0
  },
  secTitleWrapper: {
    mb: ['60px', '100px']
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px'
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['18px', '20px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67'
  },
  cardStyle: {
    p: '20px',
    m: '15px',
    borderRadius: '10px',
    boxShadow: '0px 8px 20px 0px rgba(16, 66, 97, 0.07)',
    minHeight: '86%'
  },
  cardText: {
    fontSize: ['13px', '13px', '13px', '13px'],
    fontWeight: '400',
    opacity: 0.8,
    color: '#343d48',
    lineHeight: '1.5'
  }
};
export default LeadSection;
