import technologies from './technologies.js';
import techCounts from './technologyCounts.js';

export const openSubscriptionCheckout = async () => {
  return await openCheckout(process.env.SUBSCRIPTION_ID, 'subscription', 'subscription-success');
};

export const openOneTimeCheckout = async (price) => {
  return await openCheckout(price, 'payment', 'order-success');
};

const openCheckout = async (price, mode, successUrl) => {
  if (!global.stripe)
    global.stripe = await global.stripePromise;
  const { error } = await global.stripe.redirectToCheckout({
    lineItems: [
      { price, quantity: 1 }
    ],
    mode,
    successUrl: `https://b2bsaasleads.com/${successUrl}`,
    cancelUrl: 'https://b2bsaasleads.com/'
  });
  return error;
};


export function buttonize(handlerFn) {
  return {
    role: 'button',
    onClick: handlerFn,
    onKeyDown: event => {
      // insert your preferred method for detecting the keypress
      if (event.keycode === 13) handlerFn(event);
    }
  };
}

export function nFormatter(num, digits = 1) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'B' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export const allTechKeys = Object.keys(technologies.apps)
  .filter(key => {
    const count = techCounts.find(countKey => countKey.tech === key);
    if (!count || count.count < 5) return false;
    technologies.apps[key].count = count.count;
    return true;
  });

export const processedTechs = technologies;