import technologies from '../../../utils/technologies.js';
import Box from 'common/src/components/Box';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Button from 'common/src/components/Button';
import Icon from 'react-icons-kit';
import React, { useEffect, useState } from 'react';
import { LeadSectionProps } from './index';
import ReactPaginate from 'react-paginate';
import { cart } from 'react-icons-kit/entypo/cart';
import './LeadGrid.css';
import { smallLeft } from 'react-icons-kit/entypo/smallLeft';
import { smallRight } from 'react-icons-kit/entypo/smallRight';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Highlighter from 'react-highlight-words';
import { sortAlphaAsc } from 'react-icons-kit/fa/sortAlphaAsc';
import { sortNumericDesc } from 'react-icons-kit/fa/sortNumericDesc';
import { allTechKeys, nFormatter } from '../../../utils/Utils';
import { openAddToCartModal } from './LeadModal';

export const ChipStyle = {
  fontWeight: 400,
  fontSize: '13px',
  backgroundColor: '#eee',
  borderRadius: '6px',
  padding: '2px 8px',
  marginBottom: '12px',
  marginRight: '6px'
};

const LeadGrid = ({ search }) => {
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState(false);

  search = search.toLowerCase();
  useEffect(() => {
    setOffset(0);
  }, [search]);

  /*
    function fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement('textarea');
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    }

    function copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    }

    copyTextToClipboard(JSON.stringify(Object.keys(technologies.apps)));
  */

  let techKeys;
  if (!search)
    techKeys = allTechKeys;
  else {
    techKeys = allTechKeys
      .filter(key => key.toLowerCase().includes(search) ||
        technologies.apps[key].cats.map(catKey => technologies.categories[catKey].name)
          .find(catName => catName.toLowerCase().includes(search)));
    techKeys = [...new Set(techKeys)];
  }

  if (sort)
    techKeys.sort();
  else techKeys.sort((a, b) => {
    return technologies.apps[b].count - technologies.apps[a].count;
  });


  const techCount = techKeys.length;
  const pageCount = techCount / 12;

  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected);
    setOffset(offset);
  };
  const sortIcon = sort ? sortAlphaAsc : sortNumericDesc;
  const sortText = sort ? 'Sort: A-Z' : 'Sort: Popular';
  return (
    <>
      <div style={{
        width: '100%',
        display: 'flex'
      }}>
        <Button
          variant="textButton"
          onClick={() => setSort(!sort)}
          className="mx-auto"
          iconPosition="left"
          icon={<Icon icon={sortIcon} size={14}/>}
          title={sortText}
          style={{
            fontSize: '14px',
            fontWeight: 400
          }}/>
        <div
          style={{
            fontSize: '14px',
            fontWeight: 400,
            marginLeft: '20px',
            marginTop: '10px'
          }}>
          {techCount} technologies found
        </div>
      </div>

      {techCount ? <Box key={offset} className="row" {...LeadSectionProps.row}>
        {techKeys.slice(offset * 12, (offset + 1) * 12).map((key) => (
          <Box className="col" {...LeadSectionProps.col}>
            <Card {...LeadSectionProps.cardStyle}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {technologies.apps[key].cats.map((catKey) => (
                  <div
                    key={catKey}
                    style={ChipStyle}
                  >
                    <Highlighter
                      searchWords={[search]}
                      autoEscape={true}
                      textToHighlight={technologies.categories[catKey].name}/>
                  </div>
                ))}
              </div>
              <div style={{
                display: 'flex',
                marginBottom: '6px'
              }}>
                <div
                  style={{
                    width: '22px',
                    minWidth: '22px',
                    marginRight: '6px'
                  }}
                >
                  <Image
                    key={key}
                    alt={key}
                    src={`https://static.coscout.com/images/tech/${technologies.apps[key].icon}`}
                    style={{
                      height: '22px',
                      width: '22px'
                    }}
                  />
                </div>
                <a href={technologies.apps[key].website} target="_blank" rel="noreferrer">
                  <Text
                    content={<Highlighter
                      searchWords={[search]}
                      autoEscape={true}
                      textToHighlight={key}
                    />}
                    style={{
                      marginRight: 'auto',
                      color: 'black',
                      fontWeight: 500,
                      fontSize: '19px',
                      marginBottom: '0'
                      // minHeight: '44px'
                    }}
                  />
                </a>
              </div>
              <Text
                content={`${nFormatter(technologies.apps[key].count)} companies using ${key}`}
                {...LeadSectionProps.cardText}
                style={{/* minHeight: '40px',*/ marginBottom: '8px' }}
              />
              <div style={{ width: '100%', display: 'flex' }}>
                <Button
                  // variant="outlined"
                  onClick={() => openAddToCartModal(key)}
                  iconPosition="left"
                  colors="primaryWithBg"
                  icon={<Icon icon={cart} size={14}/>}
                  title="Add to cart"
                  style={{
                    marginLeft: 'auto',
                    fontSize: '14px',
                    fontWeight: 400
                  }}/>
              </div>
            </Card>
          </Box>
        ))}
      </Box> : null}
      {techCount ? <Box style={{ marginBottom: '70px' }}>
        <ReactPaginate
          activeClassName={'item active '}
          breakClassName={'item break-me '}
          key={techKeys}
          breakLabel={'...'}
          containerClassName={'pagination'}
          disabledClassName={'disabled-page'}
          nextClassName={'item next '}
          pageClassName={'item pagination-page '}
          previousClassName={'item previous'}
          pageCount={pageCount}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          marginPagesDisplayed={1}
          previousLabel={
            <AnchorLink href="#leads">
              <Icon icon={smallLeft} size={14}/>
            </AnchorLink>
          }
          nextLabel={
            <AnchorLink href="#leads">
              <Icon icon={smallRight} size={14}/>
            </AnchorLink>
          }
          subContainerClassName={'pages pagination'}
        />
      </Box> : null}

      {!techCount && <Box style={{ marginBottom: '70px', marginTop: '70px', textAlign: 'center' }}>
        <div>No technologies found for "<strong>{search}</strong>"</div>
        <br/>
        <div>Want a custom list of companies using {search}? <a
          href="mailto:hello@b2bsaasleads.com">
          Contact us.
        </a></div>
      </Box>}
    </>
  );
};

export default LeadGrid;
