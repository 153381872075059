export default [
  {
    'tech': '1C-Bitrix',
    'count': 233
  },
  {
    'tech': '3dCart',
    'count': 12
  },
  {
    'tech': 'A-Frame',
    'count': 6
  },
  {
    'tech': 'AD EBiS',
    'count': 2
  },
  {
    'tech': 'ADPLAN',
    'count': 9
  },
  {
    'tech': 'AMP',
    'count': 225
  },
  {
    'tech': 'AMP Plugin',
    'count': 32
  },
  {
    'tech': 'AOLserver',
    'count': 3
  },
  {
    'tech': 'AT Internet Analyzer',
    'count': 108
  },
  {
    'tech': 'AT Internet XiTi',
    'count': 44
  },
  {
    'tech': 'Acquia Cloud',
    'count': 1076
  },
  {
    'tech': 'Act-On',
    'count': 659
  },
  {
    'tech': 'AdOcean',
    'count': 14
  },
  {
    'tech': 'AdRiver',
    'count': 17
  },
  {
    'tech': 'AdRoll',
    'count': 5074
  },
  {
    'tech': 'AddShoppers',
    'count': 3
  },
  {
    'tech': 'AddThis',
    'count': 6427
  },
  {
    'tech': 'AddToAny',
    'count': 3727
  },
  {
    'tech': 'Adobe ColdFusion',
    'count': 37
  },
  {
    'tech': 'Adobe DTM',
    'count': 2414
  },
  {
    'tech': 'Adobe Experience Manager',
    'count': 1845
  },
  {
    'tech': 'Adobe GoLive',
    'count': 21
  },
  {
    'tech': 'Adobe RoboHelp',
    'count': 1
  },
  {
    'tech': 'Advert Stream',
    'count': 1
  },
  {
    'tech': 'Adverticum',
    'count': 1
  },
  {
    'tech': 'Adyen',
    'count': 29
  },
  {
    'tech': 'Adzerk',
    'count': 31
  },
  {
    'tech': 'Afosto',
    'count': 1
  },
  {
    'tech': 'Ahoy',
    'count': 256
  },
  {
    'tech': 'Aircall',
    'count': 3
  },
  {
    'tech': 'Akamai',
    'count': 1919
  },
  {
    'tech': 'Akka HTTP',
    'count': 5
  },
  {
    'tech': 'Algolia',
    'count': 587
  },
  {
    'tech': 'All in One SEO Pack',
    'count': 3625
  },
  {
    'tech': 'AlloyUI',
    'count': 203
  },
  {
    'tech': 'Alpine.js',
    'count': 86
  },
  {
    'tech': 'Amaya',
    'count': 1
  },
  {
    'tech': 'Amazon ALB',
    'count': 2146
  },
  {
    'tech': 'Amazon Cloudfront',
    'count': 10212
  },
  {
    'tech': 'Amazon EC2',
    'count': 2938
  },
  {
    'tech': 'Amazon ECS',
    'count': 39
  },
  {
    'tech': 'Amazon ELB',
    'count': 1181
  },
  {
    'tech': 'Amazon S3',
    'count': 6246
  },
  {
    'tech': 'Amazon Web Services',
    'count': 16881
  },
  {
    'tech': 'Amplitude',
    'count': 1196
  },
  {
    'tech': 'Analysys Ark',
    'count': 3
  },
  {
    'tech': 'Angular',
    'count': 2006
  },
  {
    'tech': 'Angular Material',
    'count': 242
  },
  {
    'tech': 'AngularDart',
    'count': 7
  },
  {
    'tech': 'AngularJS',
    'count': 5497
  },
  {
    'tech': 'Ant Design',
    'count': 380
  },
  {
    'tech': 'Apache',
    'count': 92361
  },
  {
    'tech': 'Apache Tomcat',
    'count': 774
  },
  {
    'tech': 'Apache Traffic Server',
    'count': 365
  },
  {
    'tech': 'Apache Wicket',
    'count': 20
  },
  {
    'tech': 'ApexPages',
    'count': 48
  },
  {
    'tech': 'Apollo',
    'count': 82
  },
  {
    'tech': 'ApostropheCMS',
    'count': 20
  },
  {
    'tech': 'AppDynamics',
    'count': 178
  },
  {
    'tech': 'AppNexus',
    'count': 2480
  },
  {
    'tech': 'Arc Publishing',
    'count': 34
  },
  {
    'tech': 'ArcGIS API for JavaScript',
    'count': 19
  },
  {
    'tech': 'ArvanCloud',
    'count': 18
  },
  {
    'tech': 'Asciinema',
    'count': 11
  },
  {
    'tech': 'Atlassian Confluence',
    'count': 5
  },
  {
    'tech': 'Atlassian FishEye',
    'count': 1
  },
  {
    'tech': 'Atlassian Jira',
    'count': 6
  },
  {
    'tech': 'Atlassian Jira Issue Collector',
    'count': 23
  },
  {
    'tech': 'Atlassian Statuspage',
    'count': 1
  },
  {
    'tech': 'Aurelia',
    'count': 19
  },
  {
    'tech': 'Automattic',
    'count': 425
  },
  {
    'tech': 'Avangate',
    'count': 4
  },
  {
    'tech': 'Awesomplete',
    'count': 75
  },
  {
    'tech': 'Azure',
    'count': 3274
  },
  {
    'tech': 'Azure CDN',
    'count': 237
  },
  {
    'tech': 'BEM',
    'count': 17
  },
  {
    'tech': 'Babel',
    'count': 1567
  },
  {
    'tech': 'Bablic',
    'count': 65
  },
  {
    'tech': 'Backbone.js',
    'count': 5236
  },
  {
    'tech': 'Backdrop',
    'count': 19
  },
  {
    'tech': 'Baidu Analytics (百度统计)',
    'count': 2398
  },
  {
    'tech': 'BaseHTTP',
    'count': 5
  },
  {
    'tech': 'Big Cartel',
    'count': 4
  },
  {
    'tech': 'Bigcommerce',
    'count': 356
  },
  {
    'tech': 'Bigware',
    'count': 1
  },
  {
    'tech': 'Bizweb',
    'count': 1
  },
  {
    'tech': 'Blazor',
    'count': 9
  },
  {
    'tech': 'Blip.tv',
    'count': 2
  },
  {
    'tech': 'Blogger',
    'count': 103
  },
  {
    'tech': 'Bloomreach',
    'count': 67
  },
  {
    'tech': 'Bluefish',
    'count': 5
  },
  {
    'tech': 'Boa',
    'count': 1
  },
  {
    'tech': 'Bokeh',
    'count': 8
  },
  {
    'tech': 'Bold Chat',
    'count': 121
  },
  {
    'tech': 'BoldGrid',
    'count': 31
  },
  {
    'tech': 'Bolt',
    'count': 33
  },
  {
    'tech': 'Bonfire',
    'count': 6
  },
  {
    'tech': 'Bootstrap',
    'count': 78586
  },
  {
    'tech': 'Bootstrap Table',
    'count': 51
  },
  {
    'tech': 'Botble CMS',
    'count': 1
  },
  {
    'tech': 'Bounce Exchange',
    'count': 220
  },
  {
    'tech': 'Braintree',
    'count': 116
  },
  {
    'tech': 'Brightspot',
    'count': 35
  },
  {
    'tech': 'BrowserCMS',
    'count': 1
  },
  {
    'tech': 'Bubble',
    'count': 56
  },
  {
    'tech': 'BugSnag',
    'count': 1679
  },
  {
    'tech': 'Bulma',
    'count': 193
  },
  {
    'tech': 'Burning Board',
    'count': 1
  },
  {
    'tech': 'Business Catalyst',
    'count': 140
  },
  {
    'tech': 'BuySellAds',
    'count': 67
  },
  {
    'tech': 'CDN77',
    'count': 5
  },
  {
    'tech': 'CFML',
    'count': 40
  },
  {
    'tech': 'CKEditor',
    'count': 542
  },
  {
    'tech': 'CMS Made Simple',
    'count': 37
  },
  {
    'tech': 'CMSimple',
    'count': 1
  },
  {
    'tech': 'CNZZ',
    'count': 324
  },
  {
    'tech': 'CS Cart',
    'count': 44
  },
  {
    'tech': 'CacheFly',
    'count': 1
  },
  {
    'tech': 'Caddy',
    'count': 135
  },
  {
    'tech': 'Cafe24',
    'count': 16
  },
  {
    'tech': 'CakePHP',
    'count': 224
  },
  {
    'tech': 'Carbon Ads',
    'count': 33
  },
  {
    'tech': 'Cargo',
    'count': 25
  },
  {
    'tech': 'CentOS',
    'count': 4429
  },
  {
    'tech': 'Chart.js',
    'count': 8397
  },
  {
    'tech': 'Chartbeat',
    'count': 544
  },
  {
    'tech': 'CherryPy',
    'count': 5
  },
  {
    'tech': 'Chevereto',
    'count': 1
  },
  {
    'tech': 'Chitika',
    'count': 1
  },
  {
    'tech': 'Chorus',
    'count': 16
  },
  {
    'tech': 'Clarity',
    'count': 3
  },
  {
    'tech': 'ClickFunnels',
    'count': 51
  },
  {
    'tech': 'ClickHeat',
    'count': 18
  },
  {
    'tech': 'ClickTale',
    'count': 4
  },
  {
    'tech': 'Clicky',
    'count': 1142
  },
  {
    'tech': 'Clipboard.js',
    'count': 1095
  },
  {
    'tech': 'CloudSuite',
    'count': 2
  },
  {
    'tech': 'Cloudflare',
    'count': 41175
  },
  {
    'tech': 'Coaster CMS',
    'count': 1
  },
  {
    'tech': 'CodeIgniter',
    'count': 2012
  },
  {
    'tech': 'CodeMirror',
    'count': 163
  },
  {
    'tech': 'CoinHive',
    'count': 5
  },
  {
    'tech': 'CoinHive Captcha',
    'count': 1
  },
  {
    'tech': 'Coinimp',
    'count': 4
  },
  {
    'tech': 'Commerce Server',
    'count': 17
  },
  {
    'tech': 'Concrete5',
    'count': 427
  },
  {
    'tech': 'Contao',
    'count': 193
  },
  {
    'tech': 'Contenido',
    'count': 11
  },
  {
    'tech': 'Contensis',
    'count': 3
  },
  {
    'tech': 'Contentful',
    'count': 957
  },
  {
    'tech': 'Coppermine',
    'count': 1
  },
  {
    'tech': 'Countly',
    'count': 19
  },
  {
    'tech': 'Cowboy',
    'count': 1674
  },
  {
    'tech': 'Craft CMS',
    'count': 1089
  },
  {
    'tech': 'Craft Commerce',
    'count': 21
  },
  {
    'tech': 'Crazy Egg',
    'count': 5270
  },
  {
    'tech': 'Criteo',
    'count': 2247
  },
  {
    'tech': 'Cross Pixel',
    'count': 22
  },
  {
    'tech': 'Cufon',
    'count': 587
  },
  {
    'tech': 'D3',
    'count': 1097
  },
  {
    'tech': 'DHTMLX',
    'count': 3
  },
  {
    'tech': 'DM Polopoly',
    'count': 7
  },
  {
    'tech': 'DNN',
    'count': 642
  },
  {
    'tech': 'Dancer',
    'count': 12
  },
  {
    'tech': 'Dart',
    'count': 7
  },
  {
    'tech': 'Darwin',
    'count': 2
  },
  {
    'tech': 'DataLife Engine',
    'count': 5
  },
  {
    'tech': 'DataTables',
    'count': 2267
  },
  {
    'tech': 'Datadome',
    'count': 54
  },
  {
    'tech': 'DatoCMS',
    'count': 108
  },
  {
    'tech': 'Day.js',
    'count': 32
  },
  {
    'tech': 'Debian',
    'count': 3458
  },
  {
    'tech': 'DedeCMS',
    'count': 9
  },
  {
    'tech': 'DirectAdmin',
    'count': 1
  },
  {
    'tech': 'Discourse',
    'count': 24
  },
  {
    'tech': 'Discuz! X',
    'count': 16
  },
  {
    'tech': 'Disqus',
    'count': 1744
  },
  {
    'tech': 'Django',
    'count': 1525
  },
  {
    'tech': 'Docker',
    'count': 173
  },
  {
    'tech': 'Docusaurus',
    'count': 30
  },
  {
    'tech': 'Dojo',
    'count': 1414
  },
  {
    'tech': 'DokuWiki',
    'count': 11
  },
  {
    'tech': 'DoubleClick Ad Exchange (AdX)',
    'count': 377
  },
  {
    'tech': 'DoubleClick Campaign Manager (DCM)',
    'count': 96
  },
  {
    'tech': 'DoubleClick Floodlight',
    'count': 105
  },
  {
    'tech': 'DoubleClick for Publishers (DFP)',
    'count': 2572
  },
  {
    'tech': 'DovetailWRP',
    'count': 1
  },
  {
    'tech': 'Doxygen',
    'count': 1
  },
  {
    'tech': 'DreamWeaver',
    'count': 1873
  },
  {
    'tech': 'Drupal',
    'count': 7047
  },
  {
    'tech': 'Drupal Commerce',
    'count': 33
  },
  {
    'tech': 'Duda',
    'count': 385
  },
  {
    'tech': 'Dynamicweb',
    'count': 61
  },
  {
    'tech': 'Dynatrace',
    'count': 43
  },
  {
    'tech': 'EC-CUBE',
    'count': 8
  },
  {
    'tech': 'EKM',
    'count': 5
  },
  {
    'tech': 'EPages',
    'count': 5
  },
  {
    'tech': 'EPiServer',
    'count': 13
  },
  {
    'tech': 'EasyEngine',
    'count': 134
  },
  {
    'tech': 'Ecwid',
    'count': 58
  },
  {
    'tech': 'EdgeCast',
    'count': 63
  },
  {
    'tech': 'Element UI',
    'count': 744
  },
  {
    'tech': 'Elementor',
    'count': 10313
  },
  {
    'tech': 'Elm',
    'count': 7
  },
  {
    'tech': 'Elm-ui',
    'count': 1
  },
  {
    'tech': 'Eloqua',
    'count': 101
  },
  {
    'tech': 'Ember.js',
    'count': 210
  },
  {
    'tech': 'Ensighten',
    'count': 526
  },
  {
    'tech': 'Envoy',
    'count': 518
  },
  {
    'tech': 'Enyo',
    'count': 1
  },
  {
    'tech': 'Erlang',
    'count': 1674
  },
  {
    'tech': 'Essential JS 2',
    'count': 6
  },
  {
    'tech': 'Exhibit',
    'count': 54
  },
  {
    'tech': 'ExpertRec',
    'count': 19
  },
  {
    'tech': 'Express',
    'count': 4583
  },
  {
    'tech': 'ExpressionEngine',
    'count': 653
  },
  {
    'tech': 'ExtJS',
    'count': 67
  },
  {
    'tech': 'F5 BigIP',
    'count': 1687
  },
  {
    'tech': 'FAST Search for SharePoint',
    'count': 1
  },
  {
    'tech': 'Facebook',
    'count': 71291
  },
  {
    'tech': 'FancyBox',
    'count': 12186
  },
  {
    'tech': 'Fastly',
    'count': 1041
  },
  {
    'tech': 'Fastspring',
    'count': 49
  },
  {
    'tech': 'Fat-Free Framework',
    'count': 21
  },
  {
    'tech': 'Fbits',
    'count': 2
  },
  {
    'tech': 'Fedora',
    'count': 60
  },
  {
    'tech': 'Fingerprintjs',
    'count': 1914
  },
  {
    'tech': 'Firebase',
    'count': 799
  },
  {
    'tech': 'Fireblade',
    'count': 167
  },
  {
    'tech': 'Flarum',
    'count': 4
  },
  {
    'tech': 'Flask',
    'count': 15
  },
  {
    'tech': 'Flat UI',
    'count': 87
  },
  {
    'tech': 'FlexCMP',
    'count': 2
  },
  {
    'tech': 'FlexSlider',
    'count': 2064
  },
  {
    'tech': 'Flickity',
    'count': 3915
  },
  {
    'tech': 'Flywheel',
    'count': 2113
  },
  {
    'tech': 'Font Awesome',
    'count': 83080
  },
  {
    'tech': 'Fork CMS',
    'count': 7
  },
  {
    'tech': 'Fortune3',
    'count': 2
  },
  {
    'tech': 'Foswiki',
    'count': 1
  },
  {
    'tech': 'FreeBSD',
    'count': 163
  },
  {
    'tech': 'Freespee',
    'count': 19
  },
  {
    'tech': 'Freshchat',
    'count': 894
  },
  {
    'tech': 'Freshmarketer',
    'count': 272
  },
  {
    'tech': 'Froala Editor',
    'count': 450
  },
  {
    'tech': 'FrontPage',
    'count': 98
  },
  {
    'tech': 'Future Shop',
    'count': 2
  },
  {
    'tech': 'GOV.UK Frontend',
    'count': 16
  },
  {
    'tech': 'GSAP',
    'count': 13894
  },
  {
    'tech': 'GX WebManager',
    'count': 5
  },
  {
    'tech': 'Gallery',
    'count': 1
  },
  {
    'tech': 'Gambio',
    'count': 2
  },
  {
    'tech': 'Gatsby',
    'count': 2244
  },
  {
    'tech': 'Gauges',
    'count': 151
  },
  {
    'tech': 'Gemius',
    'count': 105
  },
  {
    'tech': 'Gentoo',
    'count': 377
  },
  {
    'tech': 'Get Satisfaction',
    'count': 18
  },
  {
    'tech': 'GetSimple CMS',
    'count': 2
  },
  {
    'tech': 'Ghost',
    'count': 261
  },
  {
    'tech': 'GitBook',
    'count': 2
  },
  {
    'tech': 'GitHub Pages',
    'count': 1147
  },
  {
    'tech': 'GitLab',
    'count': 4
  },
  {
    'tech': 'GitLab CI',
    'count': 1
  },
  {
    'tech': 'GlassFish',
    'count': 8
  },
  {
    'tech': 'Glyphicons',
    'count': 376
  },
  {
    'tech': 'Go',
    'count': 140
  },
  {
    'tech': 'GoCache',
    'count': 25
  },
  {
    'tech': 'GoDaddy Website Builder',
    'count': 454
  },
  {
    'tech': 'GoStats',
    'count': 1
  },
  {
    'tech': 'Gogs',
    'count': 1
  },
  {
    'tech': 'Google AdSense',
    'count': 6088
  },
  {
    'tech': 'Google Analytics',
    'count': 189397
  },
  {
    'tech': 'Google Analytics Enhanced eCommerce',
    'count': 9010
  },
  {
    'tech': 'Google App Engine',
    'count': 700
  },
  {
    'tech': 'Google Charts',
    'count': 64
  },
  {
    'tech': 'Google Cloud',
    'count': 3697
  },
  {
    'tech': 'Google Code Prettify',
    'count': 610
  },
  {
    'tech': 'Google Font API',
    'count': 143065
  },
  {
    'tech': 'Google Maps',
    'count': 26056
  },
  {
    'tech': 'Google PageSpeed',
    'count': 4281
  },
  {
    'tech': 'Google Plus',
    'count': 5888
  },
  {
    'tech': 'Google Tag Manager',
    'count': 117432
  },
  {
    'tech': 'Google Wallet',
    'count': 10
  },
  {
    'tech': 'Google Web Server',
    'count': 109
  },
  {
    'tech': 'Google Web Toolkit',
    'count': 141
  },
  {
    'tech': 'Graffiti CMS',
    'count': 1
  },
  {
    'tech': 'Grav',
    'count': 88
  },
  {
    'tech': 'Gravatar',
    'count': 5456
  },
  {
    'tech': 'Gravity Forms',
    'count': 11170
  },
  {
    'tech': 'Green Valley CMS',
    'count': 2
  },
  {
    'tech': 'Gridsome',
    'count': 61
  },
  {
    'tech': 'GrowingIO',
    'count': 132
  },
  {
    'tech': 'HHVM',
    'count': 17
  },
  {
    'tech': 'Hammer.js',
    'count': 5813
  },
  {
    'tech': 'Handlebars',
    'count': 4487
  },
  {
    'tech': 'Haravan',
    'count': 7
  },
  {
    'tech': 'Haskell',
    'count': 1
  },
  {
    'tech': 'HeadJS',
    'count': 138
  },
  {
    'tech': 'Heap',
    'count': 1563
  },
  {
    'tech': 'Hello Bar',
    'count': 21
  },
  {
    'tech': 'Hexo',
    'count': 33
  },
  {
    'tech': 'Highcharts',
    'count': 1076
  },
  {
    'tech': 'Highlight.js',
    'count': 1098
  },
  {
    'tech': 'Highstock',
    'count': 48
  },
  {
    'tech': 'Hogan.js',
    'count': 265
  },
  {
    'tech': 'Hotjar',
    'count': 24690
  },
  {
    'tech': 'HubSpot',
    'count': 17936
  },
  {
    'tech': 'Hugo',
    'count': 581
  },
  {
    'tech': 'IBM Coremetrics',
    'count': 2
  },
  {
    'tech': 'IBM DataPower',
    'count': 38
  },
  {
    'tech': 'IBM HTTP Server',
    'count': 12
  },
  {
    'tech': 'IBM WebSphere Commerce',
    'count': 103
  },
  {
    'tech': 'IBM WebSphere Portal',
    'count': 11
  },
  {
    'tech': 'IIS',
    'count': 27752
  },
  {
    'tech': 'INFOnline',
    'count': 65
  },
  {
    'tech': 'IPB',
    'count': 24
  },
  {
    'tech': 'Ideasoft',
    'count': 1
  },
  {
    'tech': 'IdoSell Shop',
    'count': 1
  },
  {
    'tech': 'Immutable.js',
    'count': 7
  },
  {
    'tech': 'ImpressPages',
    'count': 6
  },
  {
    'tech': 'Incapsula',
    'count': 1833
  },
  {
    'tech': 'Indy',
    'count': 2
  },
  {
    'tech': 'InfernoJS',
    'count': 1
  },
  {
    'tech': 'Infusionsoft',
    'count': 8
  },
  {
    'tech': 'Inspectlet',
    'count': 1042
  },
  {
    'tech': 'Instabot',
    'count': 74
  },
  {
    'tech': 'InstantCMS',
    'count': 1
  },
  {
    'tech': 'IntenseDebate',
    'count': 2
  },
  {
    'tech': 'Intercom',
    'count': 5593
  },
  {
    'tech': 'Intershop',
    'count': 30
  },
  {
    'tech': 'Ionic',
    'count': 21
  },
  {
    'tech': 'Ionicons',
    'count': 3269
  },
  {
    'tech': 'Irroba',
    'count': 1
  },
  {
    'tech': 'J2Store',
    'count': 24
  },
  {
    'tech': 'JBoss Application Server',
    'count': 24
  },
  {
    'tech': 'JBoss Web',
    'count': 16
  },
  {
    'tech': 'JS Charts',
    'count': 2
  },
  {
    'tech': 'JTL Shop',
    'count': 9
  },
  {
    'tech': 'Jahia DX',
    'count': 23
  },
  {
    'tech': 'Jalios',
    'count': 2
  },
  {
    'tech': 'Java',
    'count': 6344
  },
  {
    'tech': 'Java Servlet',
    'count': 211
  },
  {
    'tech': 'JavaScript Infovis Toolkit',
    'count': 6
  },
  {
    'tech': 'JavaServer Faces',
    'count': 10
  },
  {
    'tech': 'JavaServer Pages',
    'count': 112
  },
  {
    'tech': 'Jekyll',
    'count': 235
  },
  {
    'tech': 'Jetshop',
    'count': 4
  },
  {
    'tech': 'Jetty',
    'count': 64
  },
  {
    'tech': 'Jimdo',
    'count': 65
  },
  {
    'tech': 'Jitsi',
    'count': 2
  },
  {
    'tech': 'Jive',
    'count': 1
  },
  {
    'tech': 'JobberBase',
    'count': 1
  },
  {
    'tech': 'Joomla',
    'count': 2500
  },
  {
    'tech': 'K2',
    'count': 140
  },
  {
    'tech': 'KISSmetrics',
    'count': 258
  },
  {
    'tech': 'Kajabi',
    'count': 26
  },
  {
    'tech': 'Kampyle',
    'count': 110
  },
  {
    'tech': 'Kendo UI',
    'count': 433
  },
  {
    'tech': 'Kentico CMS',
    'count': 709
  },
  {
    'tech': 'Kestrel',
    'count': 399
  },
  {
    'tech': 'KeyCDN',
    'count': 19
  },
  {
    'tech': 'KineticJS',
    'count': 137
  },
  {
    'tech': 'Kinsta',
    'count': 1172
  },
  {
    'tech': 'Knockout.js',
    'count': 769
  },
  {
    'tech': 'Koa',
    'count': 3
  },
  {
    'tech': 'Koala Framework',
    'count': 1
  },
  {
    'tech': 'Kohana',
    'count': 5
  },
  {
    'tech': 'Koken',
    'count': 1
  },
  {
    'tech': 'Kooboo CMS',
    'count': 4
  },
  {
    'tech': 'Kotisivukone',
    'count': 2
  },
  {
    'tech': 'Lagoon',
    'count': 25
  },
  {
    'tech': 'Laravel',
    'count': 2482
  },
  {
    'tech': 'Leaflet',
    'count': 1375
  },
  {
    'tech': 'Less',
    'count': 121
  },
  {
    'tech': 'Liferay',
    'count': 223
  },
  {
    'tech': 'Lift',
    'count': 5
  },
  {
    'tech': 'Lightbox',
    'count': 14830
  },
  {
    'tech': 'Lightspeed eCom',
    'count': 8
  },
  {
    'tech': 'Linkedin',
    'count': 2397
  },
  {
    'tech': 'Liquid Web',
    'count': 343
  },
  {
    'tech': 'List.js',
    'count': 399
  },
  {
    'tech': 'LiteSpeed',
    'count': 4697
  },
  {
    'tech': 'Litespeed Cache',
    'count': 1349
  },
  {
    'tech': 'Lithium',
    'count': 9
  },
  {
    'tech': 'Live Story',
    'count': 5
  },
  {
    'tech': 'LiveAgent',
    'count': 105
  },
  {
    'tech': 'LiveChat',
    'count': 2316
  },
  {
    'tech': 'LivePerson',
    'count': 576
  },
  {
    'tech': 'LiveStreet CMS',
    'count': 1
  },
  {
    'tech': 'Livefyre',
    'count': 10
  },
  {
    'tech': 'Liveinternet',
    'count': 208
  },
  {
    'tech': 'Livewire',
    'count': 59
  },
  {
    'tech': 'LocalFocus',
    'count': 1
  },
  {
    'tech': 'Lodash',
    'count': 11639
  },
  {
    'tech': 'Loja Integrada',
    'count': 1
  },
  {
    'tech': 'Lotus Domino',
    'count': 30
  },
  {
    'tech': 'Lua',
    'count': 12449
  },
  {
    'tech': 'Luigi’s Box',
    'count': 6
  },
  {
    'tech': 'MODX',
    'count': 89
  },
  {
    'tech': 'Magento',
    'count': 2077
  },
  {
    'tech': 'MailChimp',
    'count': 7650
  },
  {
    'tech': 'MakeShopKorea',
    'count': 1
  },
  {
    'tech': 'MariaDB',
    'count': 2194
  },
  {
    'tech': 'Marionette.js',
    'count': 1555
  },
  {
    'tech': 'Marked',
    'count': 98
  },
  {
    'tech': 'Marketo',
    'count': 3542
  },
  {
    'tech': 'Material Design Lite',
    'count': 1607
  },
  {
    'tech': 'Materialize CSS',
    'count': 729
  },
  {
    'tech': 'MathJax',
    'count': 207
  },
  {
    'tech': 'Matomo',
    'count': 19176
  },
  {
    'tech': 'Mautic',
    'count': 488
  },
  {
    'tech': 'MaxCDN',
    'count': 24
  },
  {
    'tech': 'MaxSite CMS',
    'count': 1
  },
  {
    'tech': 'MediaElement.js',
    'count': 12859
  },
  {
    'tech': 'MediaWiki',
    'count': 32
  },
  {
    'tech': 'Medium',
    'count': 75
  },
  {
    'tech': 'Meebo',
    'count': 1
  },
  {
    'tech': 'MemberStack',
    'count': 45
  },
  {
    'tech': 'Mermaid',
    'count': 12
  },
  {
    'tech': 'Meteor',
    'count': 121
  },
  {
    'tech': 'Methode',
    'count': 2
  },
  {
    'tech': 'Microsoft ASP.NET',
    'count': 23899
  },
  {
    'tech': 'Microsoft Excel',
    'count': 2
  },
  {
    'tech': 'Microsoft HTTPAPI',
    'count': 495
  },
  {
    'tech': 'Microsoft PowerPoint',
    'count': 1
  },
  {
    'tech': 'Microsoft Publisher',
    'count': 1
  },
  {
    'tech': 'Microsoft SharePoint',
    'count': 382
  },
  {
    'tech': 'Microsoft Word',
    'count': 1148
  },
  {
    'tech': 'Milligram',
    'count': 12
  },
  {
    'tech': 'Mint',
    'count': 19
  },
  {
    'tech': 'Miva',
    'count': 17
  },
  {
    'tech': 'Mixpanel',
    'count': 2584
  },
  {
    'tech': 'MkDocs',
    'count': 11
  },
  {
    'tech': 'MobX',
    'count': 326
  },
  {
    'tech': 'Mobify',
    'count': 28
  },
  {
    'tech': 'Mobirise',
    'count': 147
  },
  {
    'tech': 'MochiKit',
    'count': 6
  },
  {
    'tech': 'Modernizr',
    'count': 55165
  },
  {
    'tech': 'Mojolicious',
    'count': 7
  },
  {
    'tech': 'Mollom',
    'count': 5
  },
  {
    'tech': 'Moment Timezone',
    'count': 176
  },
  {
    'tech': 'Moment.js',
    'count': 10956
  },
  {
    'tech': 'MongoDB',
    'count': 121
  },
  {
    'tech': 'Mongrel',
    'count': 1
  },
  {
    'tech': 'Mono',
    'count': 13
  },
  {
    'tech': 'Mono.net',
    'count': 13
  },
  {
    'tech': 'MooTools',
    'count': 1957
  },
  {
    'tech': 'Moodle',
    'count': 21
  },
  {
    'tech': 'Moon',
    'count': 3
  },
  {
    'tech': 'MotoCMS',
    'count': 10
  },
  {
    'tech': 'Mouse Flow',
    'count': 1367
  },
  {
    'tech': 'Movable Type',
    'count': 7
  },
  {
    'tech': 'Mura CMS',
    'count': 34
  },
  {
    'tech': 'Mustache',
    'count': 1410
  },
  {
    'tech': 'MyBB',
    'count': 2
  },
  {
    'tech': 'MyCashFlow',
    'count': 2
  },
  {
    'tech': 'MySQL',
    'count': 112002
  },
  {
    'tech': 'MyWebsite',
    'count': 71
  },
  {
    'tech': 'NVD3',
    'count': 92
  },
  {
    'tech': 'Navegg',
    'count': 88
  },
  {
    'tech': 'Neos CMS',
    'count': 22
  },
  {
    'tech': 'Neos Flow',
    'count': 23
  },
  {
    'tech': 'NetSuite',
    'count': 79
  },
  {
    'tech': 'Netlify',
    'count': 2490
  },
  {
    'tech': 'Neto',
    'count': 8
  },
  {
    'tech': 'Nette Framework',
    'count': 286
  },
  {
    'tech': 'New Relic',
    'count': 7203
  },
  {
    'tech': 'Next.js',
    'count': 1495
  },
  {
    'tech': 'NextGEN Gallery',
    'count': 826
  },
  {
    'tech': 'Nginx',
    'count': 97264
  },
  {
    'tech': 'Node.js',
    'count': 7975
  },
  {
    'tech': 'NodeBB',
    'count': 4
  },
  {
    'tech': 'Nuvemshop',
    'count': 18
  },
  {
    'tech': 'Nuxt.js',
    'count': 1288
  },
  {
    'tech': 'OWL Carousel',
    'count': 24801
  },
  {
    'tech': 'OXID eShop',
    'count': 17
  },
  {
    'tech': 'October CMS',
    'count': 200
  },
  {
    'tech': 'Octopress',
    'count': 4
  },
  {
    'tech': 'Odoo',
    'count': 76
  },
  {
    'tech': 'Olark',
    'count': 25
  },
  {
    'tech': 'OneAPM',
    'count': 5
  },
  {
    'tech': 'OneStat',
    'count': 2
  },
  {
    'tech': 'Open AdStream',
    'count': 19
  },
  {
    'tech': 'Open Web Analytics',
    'count': 52
  },
  {
    'tech': 'Open eShop',
    'count': 2
  },
  {
    'tech': 'OpenBSD httpd',
    'count': 4
  },
  {
    'tech': 'OpenCart',
    'count': 48
  },
  {
    'tech': 'OpenCms',
    'count': 22
  },
  {
    'tech': 'OpenGSE',
    'count': 378
  },
  {
    'tech': 'OpenLayers',
    'count': 108
  },
  {
    'tech': 'OpenNemas',
    'count': 1
  },
  {
    'tech': 'OpenResty',
    'count': 12449
  },
  {
    'tech': 'OpenSSL',
    'count': 6981
  },
  {
    'tech': 'OpenUI5',
    'count': 2
  },
  {
    'tech': 'OpenX',
    'count': 28
  },
  {
    'tech': 'Optimizely',
    'count': 2176
  },
  {
    'tech': 'Oracle Application Server',
    'count': 21
  },
  {
    'tech': 'Oracle Commerce',
    'count': 97
  },
  {
    'tech': 'Oracle Commerce Cloud',
    'count': 25
  },
  {
    'tech': 'Oracle Dynamic Monitoring Service',
    'count': 422
  },
  {
    'tech': 'Oracle HTTP Server',
    'count': 80
  },
  {
    'tech': 'Oracle Recommendations On Demand',
    'count': 86
  },
  {
    'tech': 'Oracle Web Cache',
    'count': 14
  },
  {
    'tech': 'Orchard CMS',
    'count': 77
  },
  {
    'tech': 'Outbrain',
    'count': 138
  },
  {
    'tech': 'Outlook Web App',
    'count': 3
  },
  {
    'tech': 'PDF.js',
    'count': 316
  },
  {
    'tech': 'PHP',
    'count': 149273
  },
  {
    'tech': 'PHP-Nuke',
    'count': 1
  },
  {
    'tech': 'PHPDebugBar',
    'count': 5
  },
  {
    'tech': 'PageFly',
    'count': 171
  },
  {
    'tech': 'Pagekit',
    'count': 5
  },
  {
    'tech': 'Pagely',
    'count': 251
  },
  {
    'tech': 'Pagevamp',
    'count': 2
  },
  {
    'tech': 'Pantheon',
    'count': 2194
  },
  {
    'tech': 'Pardot',
    'count': 4201
  },
  {
    'tech': 'Parse.ly',
    'count': 206
  },
  {
    'tech': 'Paths.js',
    'count': 22
  },
  {
    'tech': 'PayPal',
    'count': 948
  },
  {
    'tech': 'PencilBlue',
    'count': 4
  },
  {
    'tech': 'Percona',
    'count': 1076
  },
  {
    'tech': 'Percussion',
    'count': 3
  },
  {
    'tech': 'Perl',
    'count': 240
  },
  {
    'tech': 'Phabricator',
    'count': 2
  },
  {
    'tech': 'Phaser',
    'count': 11
  },
  {
    'tech': 'Phenomic',
    'count': 5
  },
  {
    'tech': 'Phoenix',
    'count': 50
  },
  {
    'tech': 'PhotoShelter',
    'count': 2
  },
  {
    'tech': 'Phusion Passenger',
    'count': 1114
  },
  {
    'tech': 'Pimcore',
    'count': 71
  },
  {
    'tech': 'Pinterest',
    'count': 397
  },
  {
    'tech': 'Platform.sh',
    'count': 325
  },
  {
    'tech': 'PlatformOS',
    'count': 4
  },
  {
    'tech': 'Play',
    'count': 170
  },
  {
    'tech': 'Plesk',
    'count': 4615
  },
  {
    'tech': 'Plone',
    'count': 25
  },
  {
    'tech': 'Plotly',
    'count': 27
  },
  {
    'tech': 'Plyr',
    'count': 682
  },
  {
    'tech': 'Po.st',
    'count': 8
  },
  {
    'tech': 'Polyfill',
    'count': 4528
  },
  {
    'tech': 'Polymer',
    'count': 31
  },
  {
    'tech': 'PostgreSQL',
    'count': 76
  },
  {
    'tech': 'Powerboutique',
    'count': 2
  },
  {
    'tech': 'Powergap',
    'count': 5
  },
  {
    'tech': 'Prebid',
    'count': 576
  },
  {
    'tech': 'Prefix-Free',
    'count': 437
  },
  {
    'tech': 'PrestaShop',
    'count': 304
  },
  {
    'tech': 'Prism',
    'count': 1538
  },
  {
    'tech': 'Project Wonderful',
    'count': 1
  },
  {
    'tech': 'Prototype',
    'count': 1619
  },
  {
    'tech': 'Protovis',
    'count': 2
  },
  {
    'tech': 'Proximis Unified Commerce',
    'count': 1
  },
  {
    'tech': 'PubMatic',
    'count': 47
  },
  {
    'tech': 'Public CMS',
    'count': 4
  },
  {
    'tech': 'Pure CSS',
    'count': 429
  },
  {
    'tech': 'Pygments',
    'count': 29
  },
  {
    'tech': 'PyroCMS',
    'count': 21
  },
  {
    'tech': 'Python',
    'count': 2203
  },
  {
    'tech': 'Question2Answer',
    'count': 4
  },
  {
    'tech': 'Quill',
    'count': 114
  },
  {
    'tech': 'RD Station',
    'count': 548
  },
  {
    'tech': 'RackCache',
    'count': 611
  },
  {
    'tech': 'Ramda',
    'count': 14
  },
  {
    'tech': 'Raphael',
    'count': 1019
  },
  {
    'tech': 'Raspbian',
    'count': 2
  },
  {
    'tech': 'Raychat',
    'count': 11
  },
  {
    'tech': 'ReDoc',
    'count': 12
  },
  {
    'tech': 'React',
    'count': 22372
  },
  {
    'tech': 'Red Hat',
    'count': 674
  },
  {
    'tech': 'Reddit',
    'count': 5
  },
  {
    'tech': 'Redmine',
    'count': 5
  },
  {
    'tech': 'RequireJS',
    'count': 10453
  },
  {
    'tech': 'Resin',
    'count': 22
  },
  {
    'tech': 'Reveal.js',
    'count': 56
  },
  {
    'tech': 'Revel',
    'count': 5
  },
  {
    'tech': 'Revslider',
    'count': 17748
  },
  {
    'tech': 'Rickshaw',
    'count': 14
  },
  {
    'tech': 'RightJS',
    'count': 87
  },
  {
    'tech': 'Riot',
    'count': 56
  },
  {
    'tech': 'Riskified',
    'count': 108
  },
  {
    'tech': 'Robin',
    'count': 6
  },
  {
    'tech': 'RoundCube',
    'count': 6
  },
  {
    'tech': 'Rubicon Project',
    'count': 42
  },
  {
    'tech': 'Ruby',
    'count': 6216
  },
  {
    'tech': 'Ruby on Rails',
    'count': 6030
  },
  {
    'tech': 'Ruxit',
    'count': 370
  },
  {
    'tech': 'RxJS',
    'count': 98
  },
  {
    'tech': 'SAP Commerce Cloud',
    'count': 184
  },
  {
    'tech': 'SDL Tridion',
    'count': 52
  },
  {
    'tech': 'SMF',
    'count': 10
  },
  {
    'tech': 'SPIP',
    'count': 20
  },
  {
    'tech': 'SUSE',
    'count': 30
  },
  {
    'tech': 'SWFObject',
    'count': 2015
  },
  {
    'tech': 'Sails.js',
    'count': 65
  },
  {
    'tech': 'Salesforce',
    'count': 49
  },
  {
    'tech': 'Salesforce Commerce Cloud',
    'count': 365
  },
  {
    'tech': 'Sapper',
    'count': 24
  },
  {
    'tech': 'Scala',
    'count': 175
  },
  {
    'tech': 'Scientific Linux',
    'count': 4
  },
  {
    'tech': 'Section.io',
    'count': 70
  },
  {
    'tech': 'Segment',
    'count': 3926
  },
  {
    'tech': 'Select2',
    'count': 10138
  },
  {
    'tech': 'Semantic-ui',
    'count': 348
  },
  {
    'tech': 'Sensors Data',
    'count': 120
  },
  {
    'tech': 'Sentry',
    'count': 12218
  },
  {
    'tech': 'Seravo',
    'count': 96
  },
  {
    'tech': 'ShareThis',
    'count': 725
  },
  {
    'tech': 'Shiny',
    'count': 1
  },
  {
    'tech': 'ShinyStat',
    'count': 15
  },
  {
    'tech': 'Shopatron',
    'count': 1
  },
  {
    'tech': 'Shopcada',
    'count': 1
  },
  {
    'tech': 'Shoper',
    'count': 6
  },
  {
    'tech': 'Shopify',
    'count': 5129
  },
  {
    'tech': 'Shopline',
    'count': 6
  },
  {
    'tech': 'Shopware',
    'count': 76
  },
  {
    'tech': 'Signal',
    'count': 188
  },
  {
    'tech': 'SilverStripe',
    'count': 225
  },
  {
    'tech': 'Simple Analytics',
    'count': 25
  },
  {
    'tech': 'SimpleHTTP',
    'count': 2
  },
  {
    'tech': 'Simplébo',
    'count': 1
  },
  {
    'tech': 'Site Meter',
    'count': 8
  },
  {
    'tech': 'SiteCatalyst',
    'count': 1459
  },
  {
    'tech': 'SiteGround',
    'count': 4659
  },
  {
    'tech': 'Sitecore',
    'count': 1364
  },
  {
    'tech': 'Sitefinity',
    'count': 919
  },
  {
    'tech': 'Siteglide',
    'count': 2
  },
  {
    'tech': 'Sizmek',
    'count': 423
  },
  {
    'tech': 'Slick',
    'count': 23013
  },
  {
    'tech': 'SlickStack',
    'count': 4
  },
  {
    'tech': 'Slimbox',
    'count': 42
  },
  {
    'tech': 'Slimbox 2',
    'count': 67
  },
  {
    'tech': 'Smart Ad Server',
    'count': 52
  },
  {
    'tech': 'Snap.svg',
    'count': 1600
  },
  {
    'tech': 'Snoobi',
    'count': 23
  },
  {
    'tech': 'Socket.io',
    'count': 672
  },
  {
    'tech': 'SoftTr',
    'count': 1
  },
  {
    'tech': 'Solodev',
    'count': 4
  },
  {
    'tech': 'Solusquare OmniCommerce Cloud',
    'count': 1
  },
  {
    'tech': 'Solve Media',
    'count': 2
  },
  {
    'tech': 'SonarQubes',
    'count': 1
  },
  {
    'tech': 'SoundManager',
    'count': 332
  },
  {
    'tech': 'Sphinx',
    'count': 22
  },
  {
    'tech': 'Splitbee',
    'count': 1
  },
  {
    'tech': 'Spree',
    'count': 21
  },
  {
    'tech': 'Sqreen',
    'count': 141
  },
  {
    'tech': 'Squarespace',
    'count': 7655
  },
  {
    'tech': 'Squiz Matrix',
    'count': 31
  },
  {
    'tech': 'Stackla',
    'count': 2
  },
  {
    'tech': 'Starlet',
    'count': 2
  },
  {
    'tech': 'Statcounter',
    'count': 3556
  },
  {
    'tech': 'Storeden',
    'count': 2
  },
  {
    'tech': 'Storyblok',
    'count': 2
  },
  {
    'tech': 'Strapi',
    'count': 1
  },
  {
    'tech': 'Strikingly',
    'count': 339
  },
  {
    'tech': 'Stripe',
    'count': 3645
  },
  {
    'tech': 'SublimeVideo',
    'count': 9
  },
  {
    'tech': 'Subrion',
    'count': 4
  },
  {
    'tech': 'Sucuri',
    'count': 2521
  },
  {
    'tech': 'Sulu',
    'count': 12
  },
  {
    'tech': 'SumoMe',
    'count': 571
  },
  {
    'tech': 'Supersized',
    'count': 217
  },
  {
    'tech': 'Svbtle',
    'count': 1
  },
  {
    'tech': 'Svelte',
    'count': 58
  },
  {
    'tech': 'SweetAlert',
    'count': 1367
  },
  {
    'tech': 'SweetAlert2',
    'count': 1052
  },
  {
    'tech': 'Swell',
    'count': 2
  },
  {
    'tech': 'Swiftype',
    'count': 159
  },
  {
    'tech': 'Swiper Slider',
    'count': 18359
  },
  {
    'tech': 'Symfony',
    'count': 120
  },
  {
    'tech': 'SyntaxHighlighter',
    'count': 194
  },
  {
    'tech': 'T-Soft',
    'count': 3
  },
  {
    'tech': 'TN Express Web',
    'count': 3
  },
  {
    'tech': 'TYPO3 CMS',
    'count': 1431
  },
  {
    'tech': 'TagCommander',
    'count': 131
  },
  {
    'tech': 'Tawk.to',
    'count': 4075
  },
  {
    'tech': 'Tealeaf',
    'count': 1
  },
  {
    'tech': 'Tealium',
    'count': 1504
  },
  {
    'tech': 'Telescope',
    'count': 1
  },
  {
    'tech': 'Tencent Waterproof Wall',
    'count': 25
  },
  {
    'tech': 'Tengine',
    'count': 665
  },
  {
    'tech': 'Tessitura',
    'count': 3
  },
  {
    'tech': 'Textpattern CMS',
    'count': 1
  },
  {
    'tech': 'Thelia',
    'count': 3
  },
  {
    'tech': 'ThinkPHP',
    'count': 206
  },
  {
    'tech': 'Ticimax',
    'count': 2
  },
  {
    'tech': 'Tiki Wiki CMS Groupware',
    'count': 5
  },
  {
    'tech': 'Tilda',
    'count': 427
  },
  {
    'tech': 'TinyMCE',
    'count': 533
  },
  {
    'tech': 'Titan',
    'count': 6
  },
  {
    'tech': 'TomatoCart',
    'count': 1
  },
  {
    'tech': 'TornadoServer',
    'count': 18
  },
  {
    'tech': 'TrackJs',
    'count': 6837
  },
  {
    'tech': 'Transifex',
    'count': 38
  },
  {
    'tech': 'Tray',
    'count': 3
  },
  {
    'tech': 'Tumblr',
    'count': 14
  },
  {
    'tech': 'TwistedWeb',
    'count': 1
  },
  {
    'tech': 'Twitter',
    'count': 12769
  },
  {
    'tech': 'Twitter Emoji (Twemoji)',
    'count': 64996
  },
  {
    'tech': 'Twitter Flight',
    'count': 8
  },
  {
    'tech': 'Twitter typeahead.js',
    'count': 542
  },
  {
    'tech': 'TypePad',
    'count': 3
  },
  {
    'tech': 'Typekit',
    'count': 13053
  },
  {
    'tech': 'UIKit',
    'count': 1707
  },
  {
    'tech': 'UMI.CMS',
    'count': 5
  },
  {
    'tech': 'UNIX',
    'count': 1749
  },
  {
    'tech': 'Ubercart',
    'count': 4
  },
  {
    'tech': 'Ubuntu',
    'count': 16252
  },
  {
    'tech': 'Umbraco',
    'count': 44
  },
  {
    'tech': 'Unbounce',
    'count': 1423
  },
  {
    'tech': 'Underscore.js',
    'count': 13406
  },
  {
    'tech': 'Usabilla',
    'count': 117
  },
  {
    'tech': 'UserRules',
    'count': 1
  },
  {
    'tech': 'UserVoice',
    'count': 119
  },
  {
    'tech': 'VP-ASP',
    'count': 4
  },
  {
    'tech': 'VTEX',
    'count': 29
  },
  {
    'tech': 'Vaadin',
    'count': 4
  },
  {
    'tech': 'Vanilla',
    'count': 6
  },
  {
    'tech': 'Varbase',
    'count': 8
  },
  {
    'tech': 'Varnish',
    'count': 12132
  },
  {
    'tech': 'Vercel',
    'count': 323
  },
  {
    'tech': 'VideoJS',
    'count': 2816
  },
  {
    'tech': 'VigLink',
    'count': 410
  },
  {
    'tech': 'Vignette',
    'count': 5
  },
  {
    'tech': 'Vimeo',
    'count': 16
  },
  {
    'tech': 'VirtueMart',
    'count': 7
  },
  {
    'tech': 'Virtuoso',
    'count': 1
  },
  {
    'tech': 'Visual Website Optimizer',
    'count': 2218
  },
  {
    'tech': 'Volusion',
    'count': 65
  },
  {
    'tech': 'Vue.js',
    'count': 7040
  },
  {
    'tech': 'VuePress',
    'count': 10
  },
  {
    'tech': 'W3 Total Cache',
    'count': 7009
  },
  {
    'tech': 'W3Counter',
    'count': 17
  },
  {
    'tech': 'WHMCS',
    'count': 28
  },
  {
    'tech': 'WP Engine',
    'count': 13684
  },
  {
    'tech': 'WP Rocket',
    'count': 5931
  },
  {
    'tech': 'WP-Statistics',
    'count': 685
  },
  {
    'tech': 'Warp',
    'count': 1
  },
  {
    'tech': 'Web2py',
    'count': 9
  },
  {
    'tech': 'WebGUI',
    'count': 3
  },
  {
    'tech': 'WebSite X5',
    'count': 17
  },
  {
    'tech': 'Webdev',
    'count': 1
  },
  {
    'tech': 'Webflow',
    'count': 4187
  },
  {
    'tech': 'Webix',
    'count': 3
  },
  {
    'tech': 'WebsPlanet',
    'count': 3
  },
  {
    'tech': 'Websale',
    'count': 2
  },
  {
    'tech': 'Websocket',
    'count': 6
  },
  {
    'tech': 'Webtrekk',
    'count': 66
  },
  {
    'tech': 'Webtrends',
    'count': 114
  },
  {
    'tech': 'Weebly',
    'count': 991
  },
  {
    'tech': 'Weglot',
    'count': 445
  },
  {
    'tech': 'Whooshkaa',
    'count': 8
  },
  {
    'tech': 'Wikinggruppen',
    'count': 1
  },
  {
    'tech': 'Windows Server',
    'count': 28165
  },
  {
    'tech': 'Wink',
    'count': 35
  },
  {
    'tech': 'Wix',
    'count': 7246
  },
  {
    'tech': 'Woltlab Community Framework',
    'count': 4
  },
  {
    'tech': 'WooCommerce',
    'count': 8936
  },
  {
    'tech': 'Woopra',
    'count': 318
  },
  {
    'tech': 'WordPress',
    'count': 108684
  },
  {
    'tech': 'WordPress Super Cache',
    'count': 4063
  },
  {
    'tech': 'X-Cart',
    'count': 27
  },
  {
    'tech': 'XOOPS',
    'count': 1
  },
  {
    'tech': 'XRegExp',
    'count': 303
  },
  {
    'tech': 'Xajax',
    'count': 27
  },
  {
    'tech': 'XenForo',
    'count': 48
  },
  {
    'tech': 'XpressEngine',
    'count': 5
  },
  {
    'tech': 'YUI',
    'count': 8736
  },
  {
    'tech': 'YUI Doc',
    'count': 28
  },
  {
    'tech': 'Yahoo! Ecommerce',
    'count': 31
  },
  {
    'tech': 'Yahoo! Tag Manager',
    'count': 12
  },
  {
    'tech': 'Yahoo! Web Analytics',
    'count': 1
  },
  {
    'tech': 'Yandex.Direct',
    'count': 52
  },
  {
    'tech': 'Yandex.Metrika',
    'count': 1166
  },
  {
    'tech': 'Yieldlab',
    'count': 2
  },
  {
    'tech': 'Yii',
    'count': 1405
  },
  {
    'tech': 'Yoast SEO',
    'count': 46967
  },
  {
    'tech': 'YouTube',
    'count': 47718
  },
  {
    'tech': 'ZURB Foundation',
    'count': 8390
  },
  {
    'tech': 'Zanox',
    'count': 13
  },
  {
    'tech': 'Zen Cart',
    'count': 14
  },
  {
    'tech': 'Zend',
    'count': 17
  },
  {
    'tech': 'Zendesk',
    'count': 106
  },
  {
    'tech': 'Zendesk Chat',
    'count': 2305
  },
  {
    'tech': 'Zepto',
    'count': 7253
  },
  {
    'tech': 'Zimbra',
    'count': 1
  },
  {
    'tech': 'Zinnia',
    'count': 5
  },
  {
    'tech': 'Zipkin',
    'count': 292
  },
  {
    'tech': 'Zone.js',
    'count': 547
  },
  {
    'tech': 'Zope',
    'count': 16
  },
  {
    'tech': 'a-blog cms',
    'count': 7
  },
  {
    'tech': 'amCharts',
    'count': 362
  },
  {
    'tech': 'animate.css',
    'count': 28070
  },
  {
    'tech': 'basket.js',
    'count': 200
  },
  {
    'tech': 'cPanel',
    'count': 199
  },
  {
    'tech': 'comScore',
    'count': 1516
  },
  {
    'tech': 'decimal.js',
    'count': 27
  },
  {
    'tech': 'eZ Platform',
    'count': 18
  },
  {
    'tech': 'eZ Publish',
    'count': 60
  },
  {
    'tech': 'git',
    'count': 1
  },
  {
    'tech': 'gitweb',
    'count': 1
  },
  {
    'tech': 'govCMS',
    'count': 1
  },
  {
    'tech': 'gunicorn',
    'count': 332
  },
  {
    'tech': 'iWeb',
    'count': 2
  },
  {
    'tech': 'imperia CMS',
    'count': 3
  },
  {
    'tech': 'ip-label',
    'count': 4
  },
  {
    'tech': 'jQTouch',
    'count': 2
  },
  {
    'tech': 'jQuery',
    'count': 218363
  },
  {
    'tech': 'jQuery Migrate',
    'count': 98282
  },
  {
    'tech': 'jQuery Mobile',
    'count': 1539
  },
  {
    'tech': 'jQuery Sparklines',
    'count': 81
  },
  {
    'tech': 'jQuery UI',
    'count': 48677
  },
  {
    'tech': 'jQuery-pjax',
    'count': 434
  },
  {
    'tech': 'jqPlot',
    'count': 28
  },
  {
    'tech': 'lighttpd',
    'count': 75
  },
  {
    'tech': 'math.js',
    'count': 36
  },
  {
    'tech': 'mod_auth_pam',
    'count': 2
  },
  {
    'tech': 'mod_dav',
    'count': 196
  },
  {
    'tech': 'mod_fastcgi',
    'count': 49
  },
  {
    'tech': 'mod_jk',
    'count': 224
  },
  {
    'tech': 'mod_perl',
    'count': 210
  },
  {
    'tech': 'mod_python',
    'count': 68
  },
  {
    'tech': 'mod_rack',
    'count': 42
  },
  {
    'tech': 'mod_rails',
    'count': 42
  },
  {
    'tech': 'mod_ssl',
    'count': 475
  },
  {
    'tech': 'mod_wsgi',
    'count': 221
  },
  {
    'tech': 'nopCommerce',
    'count': 88
  },
  {
    'tech': 'osCommerce',
    'count': 46
  },
  {
    'tech': 'osTicket',
    'count': 11
  },
  {
    'tech': 'papaya CMS',
    'count': 1
  },
  {
    'tech': 'parcel',
    'count': 595
  },
  {
    'tech': 'particles.js',
    'count': 2851
  },
  {
    'tech': 'phpBB',
    'count': 41
  },
  {
    'tech': 'phpMyAdmin',
    'count': 7
  },
  {
    'tech': 'phpwind',
    'count': 1
  },
  {
    'tech': 'pirobase CMS',
    'count': 1
  },
  {
    'tech': 'prettyPhoto',
    'count': 10507
  },
  {
    'tech': 'punBB',
    'count': 2
  },
  {
    'tech': 'reCAPTCHA',
    'count': 43646
  },
  {
    'tech': 'sIFR',
    'count': 41
  },
  {
    'tech': 'sNews',
    'count': 6
  },
  {
    'tech': 'script.aculo.us',
    'count': 808
  },
  {
    'tech': 'scrollreveal',
    'count': 2376
  },
  {
    'tech': 'shine.js',
    'count': 8
  },
  {
    'tech': 'styled-components',
    'count': 31
  },
  {
    'tech': 'three.js',
    'count': 611
  },
  {
    'tech': 'thttpd',
    'count': 1
  },
  {
    'tech': 'total.js',
    'count': 1
  },
  {
    'tech': 'vBulletin',
    'count': 38
  },
  {
    'tech': 'webEdition',
    'count': 6
  },
  {
    'tech': 'webpack',
    'count': 20691
  },
  {
    'tech': 'wisyCMS',
    'count': 1
  },
  {
    'tech': 'wpCache',
    'count': 49
  },
  {
    'tech': 'xCharts',
    'count': 20
  },
  {
    'tech': 'xtCommerce',
    'count': 2
  }
];