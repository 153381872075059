import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'common/src/components/Input';
import { Icon } from 'react-icons-kit';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import SearchPanelWrapper from './searchPanel.style';
import Text from 'common/src/components/Text';
import LeadModal from '../../Saas/LeadSection/LeadModal';

let key = Math.random();
let presetKey = null;

const SearchPanel = ({ search, setSearch }) => {
  const [forceSet, setForceSet] = useState('');
  const updateSearch = s => {
    setSearch(s);
    setForceSet(s);
    key = Math.random();
  };
  useEffect(() => {
    setTimeout(() => {
      if (forceSet || search) return;
      const params = new URLSearchParams(window.location.search);
      let defaultSearch = '';
      if (params.has('s')) {
        defaultSearch = decodeURIComponent(params.get('s'));
      }
      if (!defaultSearch) return;
      if (presetKey) return; // already set once
      updateSearch(defaultSearch);
      presetKey = defaultSearch;
    }, 500);
  });

  return (
    <SearchPanelWrapper>
      <Input
        inputType="text"
        key={key}
        value={search.toString()}
        forceSet={forceSet}
        onChange={e => setSearch(e)}
        iconPosition="left"
        placeholder="e.g. Zendesk, Stripe, Ecommerce, Maps"
        icon={<Icon icon={iosSearchStrong} style={{ marginLeft: '-34px' }}/>}
      />
      <div style={{ display: 'flex', float: 'right' }}>
        <Text content="" style={SearchPanel.defaultProps.hintStyle}/>
        {search && <Text content="Clear search"
                         style={SearchPanel.defaultProps.hintStyle}
                         onClick={() => updateSearch('')}/>}
        <div style={{ width: '10px' }}/>
        <LeadModal updateSearch={updateSearch}/>
      </div>
    </SearchPanelWrapper>
  );
};

// SearchPanel style props
SearchPanel.propTypes = {
  titleStyle: PropTypes.object,
  hintTextStyle: PropTypes.object
};

// SearchPanel default style
SearchPanel.defaultProps = {
  // Title default style
  titleStyle: {
    fontSize: ['24px', '30px'],
    fontWeight: '400',
    color: '#20201D',
    letterSpacing: '-0.025em',
    mb: '30px'
  },
  // hint default style
  hintStyle: {
    fontSize: '13px',
    padding: '0.25rem',
    fontWeight: '400',
    color: 'rgba(32, 32, 29, 0.55)',
    letterSpacing: '-0.025em',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
};

export default SearchPanel;
