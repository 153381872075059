import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import TiltShape from '../TiltShape';
import { BannerWrapper } from './banner.style';

const BannerSection = ({
                         heading,
                         descriptionText,
                         airtableUrl,
                         backgroundImage,
                         row,
                         contentWrapper,
                         discountAmount,
                         discountText,
                         title,
                         description,
                         imageWrapper,
                         buttonWrapper,
                         button,
                         fillButton
                       }) => {

  const BannerWrapperComp = BannerWrapper(backgroundImage);
  return (
    <BannerWrapperComp id="banner_section" style={{ backgroundImage: null }}>
      <TiltShape/>
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            {/* todo enable for top filters
            <DiscountWrapper>
              <div style={{ display: 'flex' }}>
                <Text {...discountAmount}
                      style={{ marginTop: '7px', marginRight: '8px' }}
                      content="Find leads by"/>
                <DiscountLabel>
                  <Text
                    {...discountText}
                    content="Technology"
                  />
                </DiscountLabel>
                <DiscountLabel>
                  <Text {...discountAmount} content="Country"/>
                </DiscountLabel>
                <DiscountLabel>
                  <Text {...discountAmount} content="Funding"/>
                </DiscountLabel>
              </div>
            </DiscountWrapper>*/}
            <Heading
              {...title}
              content={heading}/>
            <Text
              {...description}
              content={descriptionText}
            />
            {/*<Box {...buttonWrapper}>
              <a href="#1">
                <Button {...fillButton} title="FREE TRIAL"/>
              </a>
              <a href="#1">
                <Button
                  {...button}
                  title="WATCH VIDEO"
                  icon={<Icon icon={ic_play_circle_filled} size={30}/>}
                  iconPosition="left"
                />
              </a>
            </Box>*/}
          </Box>
          <Box {...imageWrapper}>
            <Fade bottom>
              <iframe
                title="Example B2B SaaS Leads"
                src={airtableUrl}
                frameBorder="0"
                width="100%"
                height="475"
                style={{ background: 'rgba(238,238,238,0.25)' }}
              />
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapperComp>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentWrapper: {
    width: ['100%', '100%', '99%', '85%', '85%'],
    mb: '40px'
  },
  imageWrapper: {
    width: '100%'
  },
  title: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center'
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#fff',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center'
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '600',
    color: '#00865b',
    mb: 0,
    as: 'span'
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '600',
    color: '#fff',
    mb: 0,
    as: 'span'
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto'
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px'
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'secondary',
    height: ['40px', '46px'],
    minHeight: 'auto'
  }
};

export default BannerSection;
