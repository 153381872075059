import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { base, themed } from '../base';
import LazyLoad from 'react-lazyload';

const ImageWrapper = styled('img')(
  {
    display: 'block',
    maxWidth: '100%',
    height: 'auto'
  },
  base,
  themed('Image')
);

const Image = ({ src, alt, ...props }) => {
  const [error, setError] = React.useState(false);
  if (error) return null;
  return (
    // <LazyLoad overflow once>
      <ImageWrapper src={src} alt={alt} {...props} onError={() => setError(true)}/>
    // </LazyLoad>
  );
};

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

Image.defaultProps = {
  m: 0
};
